:root {
  /* Grays */
  --gray-50: #efeef0; /* Very light gray */
  --gray-100: #edf2f7; /* Light gray */
  --gray-600: #718096; /* Medium gray */
  --gray-700: #4a5568; /* Medium-dark gray */
  --gray-800: #2d3748; /* Dark gray */
  --gray-900: #252227; /* Darkest gray */
  --gray-950: #1a202c; /* Very dark gray */
  --white: #ffffff; /* White */

  /* Brand */
  --yellow-500: #dfef00; /* Base yellow */
  --yellow-400: #ecff00; /* Brighter yellow */
  --yellow-600: #b8c500; /* Darker yellow for contrast */

  --yellow-bg-light: rgba(236, 255, 0, 0.3); /* Brighter light background */
  --yellow-bg-medium: rgba(236, 255, 0, 0.45);

  /* Success */
  --green-100: #defadc; /* Light success background */
  --green-500: #48bb78; /* Main success color */
  --green-700: #2f855a; /* Dark success color */

  /* Error */
  --red-100: #fde8e8; /* Light error background */
  --red-500: #e53e3e; /* Main error color */
  --red-700: #9b2c2c; /* Dark error color */

  /* Warning */
  --orange-100: #fff0d4; /* Light warning background */
  --orange-500: #ed8936; /* Main warning color */
  --orange-700: #c05621; /* Dark warning color */

  /* Info */
  --blue-100: #e6f2ff; /* Light info background */
  --blue-500: #4299e1; /* Main info color */
  --blue-700: #2c5282; /* Dark info color */

  --color-bg-hover: var(--gray-50);
  --color-bg: var(--white);
  --color-border-focus: var(--blue-500);
  --color-border: var(--gray-100);
  --color-highlight-alt: var(--gray-600);
  --color-highlight-alt2: var(--white);
  --color-highlight: var(--gray-900);
  --color-primary: var(--gray-900);
  --color-ring: rgba(66, 153, 225, 0.3);
  --color-secondary: var(--yellow-500);
  --color-text-muted: var(--gray-600);
  --color-text: var(--gray-900);

  --border-radius-sm: 0.25rem;
  --border-radius-md: 0.5rem;
  --border-radius-lg: 0.75rem;
  --border-radius-full: 9999px;

  --spacing-1: 0.25rem; /* 4px */
  --spacing-2: 0.5rem; /* 8px */
  --spacing-3: 0.75rem; /* 12px */
  --spacing-4: 1rem; /* 16px */
  --spacing-5: 1.25rem; /* 20px */
  --spacing-6: 1.5rem; /* 24px */
  --spacing-8: 2rem; /* 32px */

  // Semantic Tokens
  --btn-primary-bg: var(--color-primary);
  --btn-primary-text: var(--white);
  --btn-primary-border: var(--color-primary);
  --btn-primary-bg-hover: var(--color-secondary);
  --btn-primary-text-hover: var(--color-primary);
  --btn-primary-border-hover: var(--color-primary);

  --btn-secondary-bg: transparent;
  --btn-secondary-text: var(--color-primary);
  --btn-secondary-border: var(--color-primary);
  --btn-secondary-bg-hover: var(--color-primary);
  --btn-secondary-text-hover: var(--color-secondary);
  --btn-secondary-border-hover: var(--color-secondary);

  --btn-ghost-bg: transparent;
  --btn-ghost-text: var(--gray-700);
  --btn-ghost-border: var(--gray-700);
  --btn-ghost-bg-hover: rgba(74, 85, 104, 0.1);
  --btn-ghost-text-hover: var(--gray-800);
  --btn-ghost-border-hover: var(--gray-800);

  --btn-link-text: var(--color-primary);
  --btn-link-text-hover: var(--color-secondary);
  --btn-link-decoration: underline;
  --btn-link-decoration-hover: none;

  --btn-font-size: 0.875rem; /* 14px - consistent across all sizes */
  --btn-font-weight: 500;

  --btn-height-sm: 2.25rem;
  --btn-height-md: 2.5rem;
  --btn-height-lg: 2.75rem;

  --btn-padding-x-sm: 0.75rem;
  --btn-padding-x-md: 1rem;
  --btn-padding-x-lg: 2rem;

  --btn-icon-spacing: 0.3rem;

  --input-height-sm: 2.25rem;
  --input-height-md: 2.5rem;
  --input-height-lg: 2.75rem;
}

.gap-1 {
  gap: var(--spacing-1);
}
.gap-2 {
  gap: var(--spacing-2);
}
.gap-3 {
  gap: var(--spacing-3);
}
.gap-4 {
  gap: var(--spacing-4);
}
.gap-5 {
  gap: var(--spacing-5);
}
.gap-6 {
  gap: var(--spacing-6);
}
.gap-8 {
  gap: var(--spacing-8);
}

// Buttons (and links)
.mkt-btn {
  text-decoration: none;
  padding: calc(0.4em - 2px) calc(1em - 2px);
  cursor: pointer;
  font-size: var(--btn-font-size);
  font-weight: 500;
  border-radius: var(--border-radius-md);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  overflow: hidden;
  text-align: center;
  border: 1px solid;
  gap: var(--btn-icon-spacing);
  align-self: center;
  height: var(--btn-height-md);
  padding: 0 var(--btn-padding-x-md);
}

.mkt-btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  margin-right: var(--btn-icon-spacing);
  margin-top: 0.2rem;
}

.mkt-btn-sm {
  height: var(--btn-height-sm);
  padding: 0 var(--btn-padding-x-sm);
}

.mkt-btn-md {
  height: var(--btn-height-md);
  padding: 0 var(--btn-padding-x-md);
}

.mkt-btn-lg {
  height: var(--btn-height-lg);
  padding: 0 var(--btn-padding-x-lg);
}

.mkt-btn-primary {
  border: 1px solid var(--btn-primary-border);
  background-color: var(--btn-primary-bg);
  color: var(--btn-primary-text);

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--btn-primary-bg-hover);
    color: var(--btn-primary-text-hover);
    border-color: var(--btn-primary-border-hover);
  }
  
  &:disabled, &.disabled {
    opacity: 0.65;
    background-color: var(--gray-300);
    color: var(--gray-600);
    border-color: var(--gray-300);
    cursor: not-allowed;
  }
}

.mkt-btn-secondary {
  background-color: var(--btn-secondary-bg);
  color: var(--btn-secondary-text);
  border-color: var(--btn-secondary-border);

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--btn-secondary-bg-hover);
    color: var(--btn-secondary-text-hover);
    border-color: var(--btn-secondary-border-hover);
  }
  
  &:disabled, &.disabled {
    opacity: 0.65;
    background-color: var(--btn-secondary-bg);
    color: var(--gray-500);
    border-color: var(--gray-300);
    cursor: not-allowed;
  }
}

.mkt-btn-ghost {
  border: none;
  background-color: transparent;
  color: var(--gray-700);

  &:hover:not(:disabled):not(.disabled) {
    background-color: var(--gray-100);
    color: var(--gray-900);
  }

  &:focus-visible:not(:disabled):not(.disabled) {
    outline: 2px solid var(--gray-700);
    outline-offset: 2px;
  }

  &:active:not(:disabled):not(.disabled) {
    background-color: var(--gray-200);
  }
  
  &:disabled, &.disabled {
    opacity: 0.65;
    color: var(--gray-400);
    cursor: not-allowed;
  }
}

.mkt-btn-link {
  padding: 0;
  border: none;
  background: none;
  color: var(--btn-link-text);
  text-decoration: var(--btn-link-decoration);
  width: fit-content;
  flex: none;

  &:hover:not(:disabled):not(.disabled) {
    color: var(--btn-link-text-hover);
    text-decoration: var(--btn-link-decoration-hover);
  }
  
  &:disabled, &.disabled {
    opacity: 0.65;
    color: var(--gray-400);
    cursor: not-allowed;
    text-decoration: none;
  }
}

@media (max-width: 1200px) {
  .mkt-btn-primary,
  .mkt-btn-secondary {
    border-width: 1px;
  }
}

// Forms
.mkt-form-panel {
  background-color: var(--white);
  border-radius: 0.5rem;
  border: 1px solid var(--gray-100);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.mkt-form-input {
  display: block;
  width: 100%;
  height: var(--input-height-md);
  padding: 0 var(--spacing-4);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  color: var(--gray-900);
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: var(--border-radius-md);
  transition:
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.mkt-form-input:focus {
  outline: none;
  border-color: var(--blue-500);
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.3);
}

.mkt-form-input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: var(--gray-50);
}

.mkt-form-input-success {
  border-color: var(--green-500);
}

.mkt-form-input-success:focus {
  border-color: var(--green-500);
  box-shadow: 0 0 0 2px rgba(72, 187, 120, 0.3);
}

.mkt-form-input-error {
  border-color: var(--red-500);
}

.mkt-form-input-error:focus {
  border-color: var(--red-500);
  box-shadow: 0 0 0 2px rgba(229, 62, 62, 0.3);
}

.mkt-form-input::placeholder {
  color: var(--color-text-muted);
}

.mkt-form-input-sm {
  height: var(--input-height-sm);
  padding: 0 var(--spacing-3);
  font-size: var(--font-size-xs);
}

.mkt-form-input-lg {
  height: var(--input-height-lg);
  padding: 0 var(--spacing-5);
}

// Badges
.mkt-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1;
}

/* Badge Sizes */
.mkt-badge-sm {
  font-size: 0.75rem;
  padding: var(--spacing-1) var(--spacing-2);
}

.mkt-badge-md {
  font-size: 0.875rem;
  padding: var(--spacing-2) var(--spacing-3);
}

.mkt-badge-lg {
  font-size: 1rem;
  padding: var(--spacing-3) var(--spacing-4);
}

/* Border Radius */
.mkt-badge {
  border-radius: var(--border-radius-md);
}

.mkt-badge-pill {
  border-radius: var(--border-radius-full);
}

.mkt-badge-default {
  background-color: var(--gray-100);
  color: var(--gray-800);
}

.mkt-badge-primary {
  background-color: var(--gray-900);
  color: var(--white);
}

.mkt-badge-secondary {
  background-color: var(--yellow-500);
  color: var(--gray-900);
}

.mkt-badge-success {
  background-color: var(--green-500);
  color: var(--white);
}

.mkt-badge-danger {
  background-color: var(--red-500);
  color: var(--white);
}

.mkt-badge-warning {
  background-color: var(--orange-500);
  color: var(--white);
}

.mkt-badge-info {
  background-color: var(--blue-500);
  color: var(--white);
}

.mkt-badge-ghost {
  background-color: transparent;
  border: 1px solid var(--gray-700);
  color: var(--gray-700);
}

.mkt-badge-outline {
  background-color: transparent;
  border: 1px solid currentColor;
}
